





























































import Vue, { PropType } from "vue";
import exportFromJSON from "export-from-json";
import { ExcelProp } from ".";
import { pick } from "lodash";

export default Vue.extend({
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: false,
      default: 5,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    itemsPerPageOptions: {
      type: Array,
      required: false,
      default: () => [10, 20, 50],
    },
    search: {
      type: Boolean,
      required: false,
      default: false,
    },
    excel: {
      type: Object as PropType<ExcelProp | undefined>,
      required: false,
      default: undefined
    },
  },
  data: () => ({
    searchValue: "",
  }),
  methods: {
    exportToExcel() {
      if (this.excel) {
        const exportType = "xls";

        const data = this.items.map((item) =>
          pick(item, this.excel?.fields ?? [])
        );

        console.log(data);

        exportFromJSON({
          data: data,
          fileName: this.excel.filename ?? "excel",
          exportType,
          beforeTableEncode: this.excel.transformer,
        });
      }
    },
  },
});
